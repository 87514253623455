@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Zen+Tokyo+Zoo&display=swap');

/* You can add global styles to this file, and also import other style files */
*, *::before, *::after{
    box-sizing: border-box;
    margin: 0;

}
// html, body{ height: 100%;}
:root{
    --primary-color: #445c3c;
    --secondary-color: #fda77f;
    --accent-color: #b3ab74;
    --font-color: #000;


    --retro-blue: #75c8ae;
    --retro-brown: #6a5142;
    --retro-beige: #f3e6c7;
    --retro-orange: #e5771e;
    --retro-yellow: #f4a127;
    --retro-green: #5b7c51;

    background-color: var(--retro-beige);
}
.center{
    margin: auto;
}



